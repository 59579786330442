const CLASS_NAME_SHOW = 'show';

function Dialog(dialogEl, overlayEl) {

    this.dialogEl = dialogEl;
    this.overlayEl = overlayEl;

    var focusableEls = this.dialogEl.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');

    this.focusableEls = Array.prototype.slice.call(focusableEls);

    this.firstFocusableEl = this.focusableEls[0];

    this.close(); // Reset
}


Dialog.prototype.open = function() {

    var Dialog = this;

    document.getElementsByTagName('body')[0].classList.add('open');

    this.dialogEl.removeAttribute('aria-hidden');
    this.overlayEl.removeAttribute('aria-hidden');

    this.focusedElBeforeOpen = document.activeElement;

    this.modalNodes = Array.from( document.querySelectorAll('.off-canvas *') );

    // by only finding elements that do not have tabindex="-1" we ensure we don't
    // corrupt the previous state of the element if a modal was already open
    this.nonModalNodes = document.querySelectorAll('body *:not(.dialog):not([tabindex="-1"])');

    for (let i = 0; i < this.nonModalNodes.length; i++) {
        let node = this.nonModalNodes[i];

        if (!this.modalNodes.includes(node)) {
            // save the previous tabindex state so we can restore it on close
            node._prevTabindex = node.getAttribute('tabindex');
            node.setAttribute('tabindex', -1);
        }
    }

    this.dialogEl.addEventListener('keydown', function(e) {
        Dialog._handleKeyDown(e);
    });

    this.overlayEl.addEventListener('click', function() {
        Dialog.close();
    });

    this.firstFocusableEl.focus();
};

Dialog.prototype.close = function() {

    document.getElementsByTagName('body')[0].classList.remove('open');

    // restore or remove tabindex from nodes
    if (this.nonModalNodes) {
        for (let i = 0; i < this.nonModalNodes.length; i++) {
            let node = this.nonModalNodes[i];
            if (node._prevTabindex) {
                node.setAttribute('tabindex', node._prevTabindex);
                node._prevTabindex = null;
            }
            else {
                node.removeAttribute('tabindex');
            }
        }
    }

    const navItemToggles = document.querySelectorAll('.off-canvas__nav__toggle');
    resetDropdowns(navItemToggles);

    this.dialogEl.setAttribute('aria-hidden', true);
    this.overlayEl.setAttribute('aria-hidden', true);

    if ( this.focusedElBeforeOpen ) {
        this.focusedElBeforeOpen.focus();
    }
};


Dialog.prototype._handleKeyDown = function(e) {

    var Dialog = this;
    var KEY_TAB = 'Tab';
    var KEY_ESC = 'Escape';

    switch(e.key) {
        case KEY_TAB:
            if ( Dialog.focusableEls.length === 1 ) {
                e.preventDefault();
                break;
            }
            break;
        case KEY_ESC:
            Dialog.close();
            break;
        default:
            break;
    }


};

Dialog.prototype.addEventListeners = function(openDialogSel, closeDialogSel) {

    var Dialog = this;

    var openDialogEls = document.querySelectorAll(openDialogSel);
    for ( var i = 0; i < openDialogEls.length; i++ ) {
        openDialogEls[i].addEventListener('click', function() {
            Dialog.open();
        });
    }

    var closeDialogEls = document.querySelectorAll(closeDialogSel);
    for ( var i = 0; i < closeDialogEls.length; i++ ) {
        closeDialogEls[i].addEventListener('click', function() {
            Dialog.close();
        });
    }

};

function isShown(element) {
    return element.classList.contains(CLASS_NAME_SHOW);
}

function resetDropdowns(navItemToggles) {
    let dropdownSubnavs = document.querySelectorAll('.subnav');
    if (navItemToggles) {
        for (let t = 0; t < navItemToggles.length; t++) {
            navItemToggles[t].setAttribute('aria-expanded', false);
        }
    }
    if (dropdownSubnavs) {
        for (let d = 0; d < dropdownSubnavs.length; d++) {
            dropdownSubnavs[d].classList.remove(CLASS_NAME_SHOW);
        }
    }
}

function handleAriaAndCollapsedClass(toggler, collapse, isOpen) {
    if (isOpen) {
        toggler.setAttribute('aria-expanded', false);
        collapse.classList.remove(CLASS_NAME_SHOW);
    } else {
        toggler.setAttribute('aria-expanded', true);
        collapse.classList.add(CLASS_NAME_SHOW);
    }
}

// handle show/hide header while scrolling up and down
function stickyNavigation() {
    const bodyEl = document.querySelector('body');
    const siteHeader = document.querySelector('.site-header');
    let prevScrollPos = window.pageYOffset;
    let siteHeaderHeight = siteHeader.offsetHeight;
    let style = getComputedStyle(siteHeader);
    siteHeaderHeight += parseInt(style.marginTop) + parseInt(style.marginBottom);
    const gallerySidebar = document.querySelector('.sidebar-content');
    // document.documentElement.style.setProperty('--nav-height', `-${siteHeaderHeight}px`);
    let currScrollPos = window.pageYOffset;
    if (currScrollPos > 0) {
        bodyEl.classList.add('scrolled');
    } else {
        bodyEl.classList.remove('scrolled');
    }
    window.addEventListener('scroll', () => {
        console.log('scrolled');
        currScrollPos = window.pageYOffset;
        if (currScrollPos > 0) {
            bodyEl.classList.add('scrolled');
        } else {
            bodyEl.classList.remove('scrolled');
        }
        if (currScrollPos > prevScrollPos && currScrollPos > siteHeaderHeight) {
            if (siteHeader.classList.contains('nav-down')) {
                siteHeader.classList.remove('nav-down');
            }
            siteHeader.classList.add('nav-up');
            if (gallerySidebar) {
                gallerySidebar.style.top = '45px';
            }
        } else {
            if (currScrollPos > siteHeaderHeight && currScrollPos + window.innerHeight < document.body.clientHeight) {
                if (siteHeader.classList.contains('nav-up')) {
                    siteHeader.classList.remove('nav-up');
                }
                siteHeader.classList.add('nav-down');
                if (gallerySidebar) {
                    gallerySidebar.style.top = '132px';
                }
            } else {
                if (siteHeader.classList.contains('nav-up')) {
                    siteHeader.classList.remove('nav-up');
                }
                if (siteHeader.classList.contains('nav-down')) {
                    siteHeader.classList.remove('nav-down');
                }
            }
        }
        prevScrollPos = currScrollPos;
    });
}

document.addEventListener('DOMContentLoaded', () => {

    console.log('hi dom 👋');

    window.lazySizesConfig = window.lazySizesConfig || {};
    window.lazySizesConfig.expand = 300; // default 360-500
    lazySizesConfig.expFactor = 1.5; // default: 1.7

    // call sticky navigation function
    stickyNavigation();

    // global nav dialog
    const navDialogEl = document.querySelector('.off-canvas');
    const dialogOverlay = document.querySelector('.dialog-overlay');
    let myDialog = new Dialog(navDialogEl, dialogOverlay);
    myDialog.addEventListeners('.open-dialog', '.close-dialog');

    const navItemToggles = document.querySelectorAll('.off-canvas__nav__toggle');

    navItemToggles.forEach(navItemToggle => {
        navItemToggle.addEventListener('click', function() {
            let dropdownToggleId = navItemToggle.getAttribute('data-target');
            let dropdownSubnav = document.querySelector(`${dropdownToggleId}`);
            // this for loop will hide previously clicked dropdowns
            for (let j = 0; j < navItemToggles.length; j++) {
                if (navItemToggles[j] != this) {
                    let targetDropdown = document.querySelector(`${navItemToggles[j].getAttribute("data-target")}`);
                    // reset toggle not equal to clicked target
                    navItemToggles[j].setAttribute('aria-expanded', false);
                    // reset dropdown
                    targetDropdown.classList.remove(CLASS_NAME_SHOW);
                }
            }
            handleAriaAndCollapsedClass(navItemToggle, dropdownSubnav, isShown(dropdownSubnav));
        });
    });

    // gallery filter dialog
    const filterDialogEl = document.querySelector('.filter-dialog');
    const filterDialogOverlay = document.querySelector('.filter-dialog-overlay');
    if (filterDialogEl && filterDialogOverlay) {
        let filterDialog = new Dialog(filterDialogEl, filterDialogOverlay);
        filterDialog.addEventListeners('.open-filter-dialog', '.close-filter-dialog');
    }

    const answerGroups = document.querySelectorAll('.answer-group');
    const answers = document.querySelectorAll('.answers');
    const prevAnswerButton = document.querySelectorAll('.prev-group');
    if (answerGroups) {
        answerGroups.forEach(answerGroup => {
           const restartBtn = answerGroup.querySelector('.restart');
           if (restartBtn) {
               restartBtn.addEventListener('click', (e) => {
                   let btnEl = e.target;
                   // console.log(btnEl);
                   let btnElParent = btnEl.parentNode.parentNode;
                   // console.log(btnElParent);
                   let firstSibling = btnElParent.parentNode.firstElementChild;
                   showHideAnswers (btnElParent, firstSibling);
               });
           }
        });
    }
    if (answers) {
        answers.forEach(answer => {
            answer.addEventListener('click', function(e) {
                e.preventDefault();
                let el = e.target;
                // console.log(el);
                let elParent = el.parentNode;
                let elDataAttr = el.getAttribute('data-answers');
                let nextAnswer = document.querySelector(`[data-answer-target=${elDataAttr}]`);
                showHideAnswers (elParent, nextAnswer);
            });
        });
    }
    function showHideAnswers (currentAnswer, nextAnswer){
        if (currentAnswer) {
            currentAnswer.style.display = 'none';
            currentAnswer.setAttribute('tabindex', '-1');
            currentAnswer.setAttribute('aria-hidden', 'true');
        }
        if (nextAnswer) {
            nextAnswer.style.display = 'block';
            nextAnswer.setAttribute('tabindex', '1');
            nextAnswer.setAttribute('aria-hidden', 'false');
        }
    }

    const anchor = document.querySelector('#filter-top');
    const pages = document.querySelectorAll('.page-item:not(.disabled)');
    if (anchor && pages) {
        for(let i = 0; i<pages.length; i++) {
            pages[i].addEventListener('click', function(){
                anchor.scrollIntoView(
                    {
                        behavior: 'smooth', // Defines the transition animation. default: auto
                    }
                );
            });
        }
    }

// dynamically import Lazy Background Video Module
const lazyVideo = document.querySelector('.lazy-bg-video');
if (lazyVideo) {
    import ('./utils/LazyBackgroundVideo-wrapper.js');
}
});